
.main-container-div {
    max-width: 760px;
    margin: auto;
    background-color: whitesmoke;
    padding: 0 7px 7px 7px;
}

.left-part-icon-design {
    border: 2px solid white;
    padding: 7px;
    margin: 10px;
    border-radius: 1.6em 1%;
    color: white; 
    width: 17%;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #092A33;
}

.past-papers-by-year {
    background-color: yellow;
    padding: 0px;
    margin: 10px;
    border-radius: 1.6em 1%;
    background-image: linear-gradient(to right, #0a4091, #EFF4FC );
    display: flex;
}

.download-div {
    display: flex;
    border: 2px solid #092A33;
    margin: 0px;
    padding: 4px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.5em;
    background-color: #092A33;
    color: white;
}
.first-div-to-icon {
    width: 90%;
}
.icon-div {
    width: 10%;
}