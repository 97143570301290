.fraction {
    display: inline-block;
    font-size: 1.2em;
    padding: 0 0.2em;
    text-align: center;
    vertical-align: middle;
  }
  
      
  .fraction:before {
      border-bottom: 1px solid #000;
      content: attr(top);
      display: block;
      line-height: 1.6em;
      padding: 0 0.2em;
    }
   .fraction:after {
      content: attr(bottom);
      display: block;
      line-height: 1.6em;
      padding: 0 0.2em;
    } 
  .mcq-option {
      margin-left: 10px;
  }
  
  .question-item {
      border: 5px groove;
      color: black;
      font-size: medium;
  
      padding: 5px;
      /*padding-left: 14px;*/
      margin-bottom: 2px;
  }
  
      .question-item > div {
          padding-left: 14px;
          margin-top: 6px;
      }
  
      .mcq-item > div {
          font-weight: bold;
          line-height: 1.2em;
      }
  .mcq-item p > i {
      font-weight: normal;
  }
  .mcq-instruction {
      font-weight: bold;
      
  }
  .question-image {
          max-width: 280px;
          padding-top: 6px;
      }
  
  .container-m {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 10px;
      cursor: pointer;
      /*font-size: 22px;*/
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
      /* Hide the browser's default radio button */
      .container-m input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
      }
  h5 {
      font-size: 1.22em;
  }
  
  /* Create a custom radio button */
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #C0C0C0;
      border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container-m:hover input ~ .checkmark {
      background-color: #C0C0C0;
  }
  
  /* When the radio button is checked, add a blue background */
  .container-m input:checked ~ .checkmark {
      background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container-m input:checked ~ .checkmark:after {
      display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container-m .checkmark:after {
      top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  
  .checkmark-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #C0C0C0;
      border-radius: 50%;
      display: block;
  }
  .container-checkbox {
      display: block;
  }
  .container-checkbox:hover input ~ .checkmark-checkbox {
      background-color: #C0C0C0;
  }
  
  .checkbox-display {
      display: block;
  }
  
      .checkbox-display > input[type=checkbox] {
          margin-right: 10px;
      }
  
      .pagination {
          padding: 10px;
      }
  
  @keyframes animexplanation {
      from {
          visibility: hidden;
      }
  
      to {
          visibility: visible;
      }
  }
  
  .explanation {
     margin-bottom: 40px;
      /*display: none;
      visibility: hidden;*/
  }
  
  .explain {
      background-color: lightgray;
      padding: 5px;
      border-radius: 6px;
      visibility: hidden;
      display: none;
      font-size: small;
      animation-name: animexplanation;
      animation-duration: 4s;
  }
  .expanation-btn {
      margin-top: 4px;
      float: right;
      
  }
  
  .feedback-icons {
      display: none;
      visibility: hidden;
  }
  
  .show-feedback-icons {
      display: inline;
      visibility: visible;
  }
  
  .option-image {
      max-width: 300px;
      position: relative;
      /*left: -10px;
      top: -20px;*/
      z-index: 100;
  }
  