.p-container {
    padding: 6px;
    font-size: 0.8em;
    margin: 10px
}

.p-container > div {
    border: 1px solid lightgray;
    border-radius: 0.5em;
    margin: 6px 0px;
    
    background-color: white;
}