.header {
    background-color: rgb(61, 61, 35);
    background-image: url(/public/images/home2.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}


.announce-download-app {
    position: relative;
    top: -300px;
    display: grid;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10px;
    text-align: center;
}

.announce-download-app > a {
     text-decoration: none;
     padding-left: 3em;
     color: green;
     text-decoration: wavy;
     border: 1px solid rgb(14, 25, 36);
     background-color: rgb(14, 25, 36);
     padding: 10px;
     text-align: center;
     font-weight: bold;
     border-radius: 0.5em;
}

.days-to-exam {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: green;
    padding: 10px;
    border-radius: 0.5em;
}
.days-to-exam > div {
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    color: white;

}

.olympiad-announcement {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -80px;
}
.olympiad-announcement-img {
    float: left;
}
.olympiad-announcement-text {
    float: right;
    max-width: 200px;
    margin-left: 2em;
}
.spanclr {
    clear: both;
}

.bottom-page-footer {
    min-height: 200px;
    background-color: black;
    display: flex;
    justify-content: center;
}

.bottom-page-footer > div {
    /*text-align: center;*/
    font-weight: bold;
    padding: 10px;
    color: white;
}
