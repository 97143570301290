.radio {
    display: block;
    line-height: 2.5em;
}
.radio > label > span {
    padding-left: 6px;
}
.selected-pay {
    text-align: center;
   
    line-height: 1.3em;
    margin-top: 1em;
    margin-bottom: 1em;
    border-radius: 0.5em;
    width: 70%;
    margin: auto;
    color: dodgerblue;
    padding: 2px;
    border-radius: 0.5em;
    border-bottom: 1px solid dodgerblue;
    border-top: 1px solid dodgerblue;
}

.payment-description {
    /*background-color: dodgerblue;*/
    color: rgb(9, 62, 114);
    padding: 2px;
    border-radius: 0.5em;
    border-bottom: 1px solid dodgerblue;
    border-top: 1px solid dodgerblue;
    padding-right: 8px;
}

.next-pay-header {
    background-color: dodgerblue;
    color: yellow;
    padding: 10px;
    font-size: 0.8em;
    display: flex;
    gap: 2em
}

.isNextFooter {
    display: flex;
    gap: 1em;
    margin-bottom: 1.3em;
    margin-left: 0.3em;
}


.paymethod-container {
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

.centering-modal-content {
    max-width: 420px;
    margin: 0 auto;
    border: 1px solid lightgray;
}