.center-div {
  height: 700px;
  width: 100%;
  /*background: #CCCCCC;*/
  display: flex;
  align-items: center;
  justify-content: center;
  }

  .head1style {
    font-weight: bold;
    font-size: 2.99em;
  }
